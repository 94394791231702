@import "../../../../styles/variables";

.Card {
  border: 1px solid $ms-border-color;
  margin: $ms-margin;
  padding: $ms-padding;
  border-radius: $ms-border-radius;
  width: fit-content;
}

.CardHeader {
  font-size: 1rem;
  font-weight: bold;
  border-bottom: 1px solid $ms-border-color;
  padding-bottom: $ms-padding;
}

.CardBody {
  display: flex;
  padding-top: $ms-padding;
  padding-bottom: $ms-padding;
}

.CardTitle {
  font-size: 1.2rem;
  margin: 0;
}

.CardSubtitle {
  font-size: 0.8rem;
  margin: 0.5rem 0 0 0;
}

.CardBody > * {
  display: flex;
  flex-direction: column;
}

.CardFooter {
  hr {
    background-color: $ms-border-color;
    border-color: transparent;
    border-width: 0.1px;
  }
}
